import { Typography, Row, Col, List as AntList, Space, Tooltip } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { TrashIcon, CogSettingsIcon } from 'assets/icons'

import { Button as BaseButton } from '../Button'
import { ViewerUserTooltip } from '../ViewerUserTooltip'

const { Title: AntTitle } = Typography

const iconButton = `
width: 45px;
height: 45px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
`

const FlexRow = styled(Row)`
  flex-grow: 1;
  height: 100%;
`

const Button = styled(BaseButton)`
  flex-grow: 1;
  height: 55px !important;
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

const ActionsCol = styled(Col)`
  margin-left: auto;
`

interface ButtonProps {
  disabled?: boolean
}

const TrashButton = styled.div<ButtonProps>`
  ${iconButton}
  width: 40px;
  height: 40px;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey2 : theme.colors.grey3};
`

const SettingsButton = styled.div<ButtonProps>`
  ${iconButton}
  width: 40px;
  height: 40px;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey2 : theme.colors.white};
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.grey2};
  color: red;
`

const List = styled(AntList)`
  border: 0;
  & .ant-list-header {
    background: ${({ theme }) => theme.colors.grey2};
    border-radius: ${({ theme }) => theme.foundation.borderRadius};
  }
  & .ant-list-footer {
    padding: 16px 0 !important;
  }

  & .ant-list-item {
    border-bottom: ${({ theme }) => `5px solid ${theme.colors.grey2}`};
    padding: 10px 24px !important;

    border-bottom-left-radius: 2px;

    border-bottom-right-radius: 2px;
    &:last-child {
      border-bottom: 0 !important;
    }
  }
` as typeof AntList

interface ColContainerProps {
  $maxWidth?: string
}
const ColContainer = styled(Col)<ColContainerProps>`
  max-width: ${({ $maxWidth }) => $maxWidth ?? '704px'};
  flex: 1;
  background: ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};

  &.ant-col:first-child {
    padding: 30px !important;
  }
`

const Description = styled(AntTitle)`
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 24px !important;
  margin-bottom: 22px !important;
  font-size: 13px !important;
`

const Title = styled(AntTitle)`
  margin-bottom: 0 !important;
`

// TODO Create a proper empty list component
const EmptyList = () => null

interface Props<T extends { id: number }> {
  data: T[]
  title: string
  description: string
  header: React.ReactNode
  headerSubsection?: React.ReactNode
  buttonDescription: string
  loading?: boolean
  onClick: () => void
  onEdit: (id: number) => void
  onDelete: (id: number) => void
  renderItem: (item: T, index: number) => React.ReactNode
  isAdminUser: boolean
  disabledButton?: boolean
  disabledTooltipText?: string
  maxWidth?: string
}

// TODO We should probably convert this to a Table. Components using this need to tweak
// renderItem and header prop for columns to be aligned
export const ListCard = <T extends { id: number; isDisabled?: boolean }>({
  data,
  title,
  description,
  header,
  headerSubsection,
  buttonDescription,
  loading,
  onClick,
  onEdit,
  onDelete,
  renderItem,
  disabledButton,
  isAdminUser,
  disabledTooltipText,
  maxWidth,
}: Props<T>) => {
  const { colors } = useTheme()
  const { t } = useTranslation('common')

  return (
    <ColContainer $maxWidth={maxWidth}>
      <Title level={5}>{title}</Title>
      <Description>{description}</Description>
      {headerSubsection}
      <List
        locale={{ emptyText: <EmptyList /> }}
        pagination={{
          pageSize: 5,
        }}
        size="large"
        header={header}
        loading={loading}
        footer={
          isAdminUser ? (
            <>
              {disabledButton ? (
                <Tooltip title={t('messages.featureNotAvailable')}>
                  <FlexRow>
                    <Button type="primary" disabled>
                      {buttonDescription}
                    </Button>
                  </FlexRow>
                </Tooltip>
              ) : (
                <FlexRow>
                  <Button onClick={onClick} type="primary">
                    {buttonDescription}
                  </Button>
                </FlexRow>
              )}
            </>
          ) : (
            <ViewerUserTooltip>
              <FlexRow>
                <Button type="primary" disabled>
                  {buttonDescription}
                </Button>
              </FlexRow>
            </ViewerUserTooltip>
          )
        }
        bordered
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <FlexRow align="middle" wrap={false}>
              {renderItem(item, index)}
              <ActionsCol>
                <Space size={10}>
                  {isAdminUser ? (
                    <>
                      {disabledButton || item.isDisabled ? (
                        <>
                          <SettingsButton onClick={() => onEdit(item.id)}>
                            <CogSettingsIcon />
                          </SettingsButton>
                          <Tooltip title={disabledTooltipText ?? t('messages.featureNotAvailable')}>
                            <TrashButton disabled>
                              <TrashIcon color={colors.grey3} />
                            </TrashButton>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <SettingsButton onClick={() => onEdit(item.id)}>
                            <CogSettingsIcon />
                          </SettingsButton>
                          <TrashButton onClick={() => onDelete(item.id)}>
                            <TrashIcon color={colors.white} />
                          </TrashButton>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <ViewerUserTooltip>
                        <SettingsButton disabled>
                          <CogSettingsIcon />
                        </SettingsButton>
                      </ViewerUserTooltip>
                      <ViewerUserTooltip>
                        <TrashButton disabled>
                          <TrashIcon color={colors.grey3} />
                        </TrashButton>
                      </ViewerUserTooltip>
                    </>
                  )}
                </Space>
              </ActionsCol>
            </FlexRow>
          </List.Item>
        )}
      />
    </ColContainer>
  )
}
